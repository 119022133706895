/**
 * This file is exported as _fd global variable
 * scan() - scans for divs with 'form-data-powered-by' class and turns them into badge
 */


function render (div) {
    // add badge
    if (div.dataset.formdataRendered === 'true') {
        return
    }
    const darkTheme = div.dataset.formdataTheme === 'dark'
    const color = darkTheme ? '#e3e3e3' : '#222222'
    let lang = div.dataset.formdataLang || 'en'
    if (!poweredBy[lang]) {
        lang = 'en'
    }
    const origin = document.location.hostname
    const sitename = div.dataset.formdataSitename || origin
    const badge = document.createElement('div')
    badge.style.fontSize = '12pm'
    badge.style.color = color
    badge.className = 'form-data-badge'
    const text = poweredBy[lang].poweredBy
    const tokens = text.split('{{formdata}}')
    tokens.forEach(token => {
        if (token === '') {
            // append link
            const link = document.createElement('a')
            link.style.color = color
            link.style.fontWeight = 'bold'
            link.href = `https://form-data.com/badge?from=${origin}&lang=${lang}&sitename=${encodeURIComponent(sitename)}&utm_source=formdata&utm_medium=website&utm_campaign=powered-by_web&v=1`
            link.target = '_blank'
            const textFd = document.createTextNode('Form-Data')
            link.appendChild(textFd)
            badge.appendChild(link)
        } else {
            // append text
            const textNode = document.createTextNode(token)
            badge.appendChild(textNode)
        }
    })
    div.appendChild(badge, div)
    // add hidden input
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'formDataBadge'
    input.value = new Date().toISOString()
    div.parentNode.insertBefore(input, div)
    div.dataset.formdataRendered = 'true'
}


const poweredBy = {
    ar: { // Arabic
        poweredBy: 'يعمل بواسطة {{formdata}}'
    },
    bg: { // Bulgarian
        poweredBy: 'Powered by {{formdata}}'
    },
    cs: { // Czech
        poweredBy: 'Používáme {{formdata}}'
    },
    da: { // Danish
        poweredBy: 'Drevet af {{formdata}}'
    },
    de: { // German
        poweredBy: 'Powered by {{formdata}}'
    },
    el: { // Greek
        poweredBy: 'Βασισμένο στο {{formdata}}'
    },
    en: { // English
        poweredBy: 'Powered by {{formdata}}'
    },
    es: { // Spanish
        poweredBy: 'Soportado por {{formdata}}'
    },
    fa: { // Persian
        poweredBy: 'با نیروی {{formdata}}'
    },
    fi: { // Finnish
        poweredBy: 'Powered by {{formdata}}'
    },
    fr: { // French
        poweredBy: 'Propulsé par {{formdata}}'
    },
    gl: { // Galician
        poweredBy: 'Funciona con {{formdata}}'
    },
    he: { // Hebrew
        poweredBy: 'פועל על {{formdata}}'
    },
    hr: { // Croatian
        poweredBy: 'Pokreće {{formdata}}'
    },
    hu: { // Hungarian
        poweredBy: 'Meghajtja az {{formdata}}'
    },
    id: { // Indonesian
        poweredBy: 'Diberdayakan dengan {{formdata}}'
    },
    is: { // Icelandic
        poweredBy: 'Knúið af {{formdata}}'
    },
    it: { // Italian
        poweredBy: 'Powered by {{formdata}}'
    },
    ja: { // Japanese
        poweredBy: 'Powered by {{formdata}}'
    },
    ko: { // Korean
        poweredBy: '{{formdata}} 제공'
    },
    nb: { // Norwegian Bokmål
        poweredBy: 'Drevet av {{formdata}}'
    },
    nl: { // Dutch flemish
        poweredBy: 'Mogelijk gemaakt door {{formdata}}'
    },
    pl: { // Polish
        poweredBy: 'Dostarczane przez {{formdata}}'
    },
    pt: { // Portuguese
        poweredBy: 'Desenvolvido por {{formdata}}'
    },
    ro: { // Romanian
        poweredBy: 'Propulsat de {{formdata}}'
    },
    ru: { // Russian
        poweredBy: 'Работает на {{formdata}}'
    },
    sk: { // Slovak
        poweredBy: 'Založené na {{formdata}}'
    },
    sr: { //serbian (sr_RS_Cyrillic)
        poweredBy: 'Покреће {{formdata}}'
    },
    sv: { // Swedish
        poweredBy: 'Drivs av {{formdata}}'
    },
    th: { // Thai
        poweredBy: 'ขับเคลื่อนโดย {{formdata}}'
    },
    tr: { // Turkish
        poweredBy: 'Powered by {{formdata}}'
    },
    uk: { // Ukrainian
        poweredBy: 'Працює на {{formdata}}'
    },
    vi: { // Vietnamese
        poweredBy: 'Cung cấp bởi {{formdata}}'
    },
    zh_CN: { // chinese (China)
        poweredBy: '基于 {{formdata}}'
    },
    zh_TW: { // chinese (Taiwan)
        poweredBy: '技術提供者 {{formdata}}'
    }
}

async function setTimeFields() {
    const t = await fetch('https://api.form-data.com/public/time', {method:'GET', mode:'cors'})
    const json = await t.json()
    const time = '' + json.time
    const inputs = document.querySelectorAll('.form-data-marker')
    inputs.forEach(input => {
        if (input.name === '_fd') {
            input.value = time
        }
    })
}

function bindLoader(form) {
    addLoaderDiv(form)
    form.addEventListener('submit', (evt) => {
        document.querySelector('.form-data-loader-overlay').classList.add('fd-show')
    })
    // todo: this only supports 'post' forms and not ajax forms. For ajax, we also need to wait for the response from the server, and remove `fd-show` class
}

function addLoaderDiv(form) {
    const loaderDiv = document.querySelector('form-data-loader-overlay')
    if (loaderDiv) {
        return
    }
    const config = {
        loaderText: form.dataset.formdataLoaderText || ''
    }
    const html = `
<div class="form-data-loader-overlay">
  <div class="fd-overlay"></div>
  <div class="fd-spanner">
    <div class="fd-loader"></div>
    <p>${config.loaderText}</p>
  </div>
</div>`.trim()
    const style = `
<style>
.fd-spanner{
  position:fixed;
  top: 50%;
  left: 0;
  width: 100%;
  display:block;
  text-align:center;
  height: 300px;
  color: #FFF;
  transform: translateY(-50%);
  z-index: 1000;
  visibility: hidden;
  font-weight: bold;
  font-size: 1rem;
}
.fd-overlay{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
  visibility: hidden;
}
.fd-loader,
.fd-loader:before,
.fd-loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.fd-loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.fd-loader:before,
.fd-loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.fd-loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.fd-loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.fd-spanner, .fd-overlay{
  opacity: 0;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.fd-show .fd-spanner, .fd-show .fd-overlay {
  opacity: 1;
  visibility: visible;
}
</style>`.trim()
    const styleTemplate = document.createElement('template')
    styleTemplate.innerHTML = style
    document.head.appendChild(styleTemplate.content.children[0])

    const htmlTemplate = document.createElement('template')
    htmlTemplate.innerHTML = html
    document.body.appendChild(htmlTemplate.content.children[0])
}

export function scan() {
    // powered by
    const poweredByDivs = document.querySelectorAll('.form-data-powered-by')
    poweredByDivs.forEach(div => render(div))
    // timestamp fields for human detection
    setTimeFields()
    // bind form submit to show loader
    const loaderForms = document.querySelectorAll('.form-data-loader')
    loaderForms.forEach(form => bindLoader(form))
}
